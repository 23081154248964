<script setup lang="ts">
import type { Invite } from '@respell/database';
import { useWorkspaceStore } from '~/stores/workspaces';

const inviteId = useRouteParams('inviteId');
const token = useRouteQuery('token');

const workspaceStore = useWorkspaceStore();

const { data: invite } = await useApi<Invite>(
  `/api/invites/${inviteId.value as string}?token=${token.value as string}`,
);

definePageMeta({
  auth: {
    unauthenticatedOnly: false,
  },
});

async function accept() {
  const updates = {
    inviteToken: token.value,
    status: 'accepted',
  };

  await workspaceStore.updateInvite(inviteId.value as string, updates);
}

async function decline() {
  await workspaceStore.updateInvite(inviteId.value as string, {
    status: 'denied',
  });
}
</script>

<template>
  <NuxtLayout name="auth">
    <div v-if="invite" class="flex flex-col gap-3">
      <p class="title">
        You have been invited to join {{ invite.workspace.name }}
      </p>

      <UButton block size="xl" label="Accept" @click="accept" />

      <UButton block size="xl" color="white" label="Decline" @click="decline" />
    </div>
    <p v-else class="subtitle">
      We're sorry, this invitation is no longer valid.
    </p>
  </NuxtLayout>
</template>
